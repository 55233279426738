@import '../../styles/customMediaQueries.css';

.contactInnerWrapper {
  width: 100%;
  justify-content: center;
  margin: auto;
  max-width: 600px;
  padding: 0 39px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
  & textarea{
    @media (min-width: 1024px) {
      min-height: 160px;
    }
  }
  & .contactEmailBox {
    padding-right: 113px;
    @media (max-width: 1600px) {
      padding-right: 80px;
    }
    @media (max-width: 1100px) {
      padding-right: 36px;
    }
    @media (max-width: 767px) {
      padding-right: 0px;
      margin-bottom: 60px;
    }
  }

  & .nameInput {
    display: flex;
    gap: 30px;
    width: 100%;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0;
    }
  }

  & .inputBox {
    width: 100%;
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    & label {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #000;
      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  & .formsubmitWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    @media (max-width: 767px) {
      margin-top: 48px;
      margin-bottom: 60px;
    }
    & .submitButton {
      /* width: 270px; */
    }
  }
}
.sectionTitle {
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #000;
  margin: 0 0 0;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
}
